export default (nextUrl) => ({
  nextUrl: nextUrl,
  hasMore: !!nextUrl,
  showBackToBottom: false,

  async loadMoreMessages() {
    if (!this.nextUrl) {
      return;
    }

    const response = await fetch(this.nextUrl, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    const content = await response.text();

    this.nextUrl = response.headers.get("X-Next-Page");

    if (!this.nextUrl) {
      this.hasMore = false;
    }

    this.$refs.messagesPage.insertAdjacentHTML("afterbegin", content);
  },
});
